import {
  Box,
  Button,
  Flex,
  HStack,
  useMediaQuery
} from "@chakra-ui/react"
import { Link, useLocation } from 'react-router-dom'
import { useAuthStore, useOnboardingStore } from "@olagg/store"

import { Alert } from "../Alert"
import MobileNav from "./MobileNav"
import MyAccountNav from "./MyAccountNav"
import Nav from './Nav'
import { NavbarLogoOla } from '@olagg/ui-kit'
import { ROUTES } from '../../routes'
import { useTranslation } from 'react-i18next'
import Notifications from "../Notifications"
import CompletionSnack from "../CompletionSnack"

const NavbarV2 = () => {
  const navbarBg = '#2B2640'
  const { loggedIn } = useAuthStore()
  const { t } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1025px)')
  const { isOnboardingOpen, openOnboarding, closeOnboarding } = useOnboardingStore()
  const { pathname } = useLocation()
  const isProfilePath = pathname.includes(ROUTES.MY_PROFILE)

  return (
    <>
      <Flex
        w="100vw"
        maxW='100%'
        position="sticky"
        zIndex={999}
        backgroundColor={navbarBg}
        top='-1'
        borderBottom="2px solid var(--OLA-GG-colors-Gradient, #4E10FF)"
        h={'82px'}
        alignItems='center'
      >
        <Box width='100%' p={0} transition='all ease 0.3s'>
          <HStack maxWidth={import.meta.env.VITE_LAYOUT_MAXWIDTH} pb={0} justifyContent='space-between' paddingX="20px" margin='0 auto'>
            <Box marginLeft='5px'>
              {
                isLargerThan1024 ? (
                  <Link to={ROUTES.HOME} state={{ fromLogo: true }}>
                    <NavbarLogoOla />
                  </Link>
                ) : (
                  <MobileNav />
                )
              }
            </Box>
            <Flex ml="80px">
              {isLargerThan1024 ? (
                <Nav />
              ) : (
                loggedIn ? (
                  <Flex alignItems='center'>
                    <MyAccountNav />
                    {!isProfilePath && (
                      <Flex position={'absolute'} top='calc(100% + 6px)' right={'0'} px='4px'>
                        <CompletionSnack />
                      </Flex>
                    )}
                    <Flex mx='3' bgColor='gray' w='1px' h='38px'></Flex>
                    <Notifications />
                  </Flex>
                ) : (
                  <Button
                    variant="filledGradient"
                    color="white"
                    w="112px"
                    fontSize={'18px'}
                    type="button"
                    top='0'
                    _hover={{ transform: 'scale(0.98)', bg: '#4E10FF' }}
                    _active={{ transform: 'scale(0.98)', bg: '#FF00F6' }}
                    onClick={() => openOnboarding()}
                  >
                    {t('navbar.access')}
                  </Button>
                )
              )}
            </Flex>
          </HStack>
        </Box>
      </Flex>
      <Alert />
    </>
  )
}

export default NavbarV2
