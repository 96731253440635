import { Box, Flex, Progress, Text, Image } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import { Xp } from '@olagg/ui-kit/svg/Xp'
import { XpStatic } from '@olagg/ui-kit/svg/XpStatic'
import { getPercentage } from './data'
import s from './styles.module.css'
import { useAuthStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'
import Rocket from '/images/onboarding/rocket.png'

const Level = ({ mode = 'nav' }) => {
    const { me } = useAuthStore()
    const [levelData, setLevelData] = useState(me?.level)
    const [percent, setPercentage] = useState(getPercentage(me?.level))
    const prevCountRef = useRef(me?.level?.score || 0)
    const { t } = useTranslation()

    useEffect(() => {
        setLevelData(me?.level)
        setPercentage(getPercentage(me?.level))
        showAnim()
    }, [me])

    const [levelAnim, setlevelAnim] = useState(false)

    const showAnim = () => {
        if (me?.level?.score > prevCountRef.current) {
            setTimeout(() => {
                setlevelAnim(true)
            }, 500)
            setTimeout(() => {
                setlevelAnim(false)
            }, 1001)
            prevCountRef.current = me?.level?.score;
        }
    }

    return (
        <Flex flex='1' flexDirection={'column'} gap='12px'>
            <Flex direction="row" align="center">
                <Flex flex='1' color='white' fontWeight="bold" fontSize="14px" alignItems={'center'} flexDirection={'row'}>
                    <Text>{t('navbar.myAccountNav.level')} {levelData.value}</Text>
                    <Flex justifyContent={'center'} padding={'0px 8px'} borderRadius={'100px'} marginLeft={'auto'} background='linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)'>
                        <Text position={'relative'} top='1px'>{me?.level.score || 0} </Text>
                        <Text w='25px'>
                            <Xp color='' transparentBackground />
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Progress className={levelAnim ? s.glowLevel : ''} onChange={showAnim} sx={{
                background: 'rgba(255, 255, 255, 0.16)',
                '& > div': {
                    background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                    transition: 'width 500ms'
                },
            }} rounded="10px" height="10px" value={percent} />
            <Flex alignItems={'center'}>
                <Image src={Rocket} maxW='24px' />
                <Text fontSize='12px' color='white'>{t("Level.youNeed")} <Text as='strong'>{levelData.nextLevelScore - (me?.level.score || 0)}</Text></Text>
                <Text w='22px' mr={'2px'}><Xp color='' transparentBackground /></Text>
                <Text fontSize='12px' color='white'>{t("Level.toLevelUp")}</Text>
            </Flex>
        </Flex>
    )
}

export default Level
