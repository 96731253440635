import { Flex, Button, CircularProgress, CircularProgressLabel, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Medal from './assets/medal.png'
import { useOnboardingStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const CompletionSnack = () => {

    const { getObQuests, obQuests } = useOnboardingStore()
    const [completion, setCompletion] = useState(0)
    const { t, i18n } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const getProfileCompletion = () => {
        let total = obQuests?.quests ? obQuests?.quests.length : 0 // 100%
        let claimed = obQuests?.quests ? obQuests?.quests.filter(obq => obq?.status == 'CLAIMED') : 0

        let percentage = (claimed.length * 100) / total
        setCompletion(percentage)
    }

    const checkSnackStatus = () => {
        let now = new Date()
        let lastSaved = localStorage.getItem('completion_snack_date_closed')
        let last = lastSaved ? new Date(lastSaved) : now

        const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000 // 7 días en milisegundos
        // const sevenDaysInMs = 1 * 60 * 1000 // 1 min en milisegundos Test
        const hasSevenDaysPassed = (now.getTime() - last.getTime()) >= sevenDaysInMs;

        if (hasSevenDaysPassed || !lastSaved) setIsOpen(true)

    }

    useEffect(() => {
        getObQuests()
        checkSnackStatus()
    }, [])

    useEffect(() => {
        getProfileCompletion()
    }, [obQuests])

    const handleClose = () => {
        let now = new Date()
        localStorage.setItem('completion_snack_date_closed', now.toISOString())
        setIsOpen(false)
    }

    const completionTexts = () => {
        if (completion <= 49) return <>
            <Text as='span'>{t('ProfileCompletion.whatAreYouWaitingFor')} <Text as='span' textDecoration='underline'>{t('ProfileCompletion.completeProfileEarnXP')}</Text></Text>
        </>

        if (completion >= 50 && completion <= 74) return <>
            <Text as='span'>{t('ProfileCompletion.finishAndEarnXP')} <Text as='span' textDecoration='underline'>{t('ProfileCompletion.completeProfileEarnXP')}</Text></Text>
        </>

        if (completion >= 75 && completion <= 99) return <>
            <Text as='span'>{t('ProfileCompletion.almostThere')} <Text as='span' textDecoration='underline'>{t('ProfileCompletion.completeProfileEarnXP')}</Text></Text>
        </>

        if (completion == 100) return <>
            <Text as='span'>{t('ProfileCompletion.congratulations')}</Text>
        </>
    }

    return (
        <>
            {isOpen && !isNaN(completion) && (
                <Flex bgColor='#3F2091' minHeight='64px' borderRadius={'8px'} p='8px' alignItems={'center'} gap='8px' maxW={'380px'}>
                    <Flex>
                        <CircularProgress size={'32px'} capIsRound value={completion} color='#07E588' trackColor='rgba(255, 255, 255, 0.20)' thickness='7'>
                            <CircularProgressLabel>
                                <Image src={Medal} w={'22px'} h={'22px'} m={'5px'} />
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Flex>
                    <Flex flexDirection={'column'}>
                        <Text color='white' fontWeight={'700'} fontSize='14px' lineHeight={'16px'}>{Math.trunc(completion)}% {t('ProfileCompletion.profileCompletionPercentage')}</Text>
                        {completion < 100 ? (
                            <Link to={`/${i18n.language}/mi-perfil`}><Text color='white' fontWeight={'400'} fontSize='14px' lineHeight={'19px'} mt='2px'>{completionTexts()}</Text></Link>
                        ) : (
                            <Text color='white' fontWeight={'400'} fontSize='14px' lineHeight={'19px'} mt='2px'>{completionTexts()}</Text>
                        )}
                    </Flex>
                    <Flex ml='auto'>
                        <Button p='4px' minW={'20px'} onClick={handleClose} variant={'unstyled'} color='white'><FaXmark /></Button>
                    </Flex>
                </Flex>
            )}
        </>
    )
}

export default CompletionSnack
